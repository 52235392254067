<template>
    <dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ venueFeature.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.venue-features.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name" v-model="venueFeature.name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn @click="handleSave">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import VenueFeature from "@/models/VenueFeature";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "venue-feature-form",
    components: {DashboardLayout},
    data: function () {
        return {
            venueFeature: {}
        }
    },
    async mounted() {
        const id = this.$route.params.id
        this.venueFeature = id ? await VenueFeature.find(id) : new VenueFeature();
    },
    methods: {
        async handleSave() {
            this.venueFeature.save()
        }
    }
}
</script>

<style scoped>

</style>
